
























































    import 'reflect-metadata';
    import { Component, Prop, Vue } from 'vue-property-decorator';
	import ArticleCard from '@/components/ArticleCard.vue';
	import URL from '@/components/URL.vue';

	// @ts-ignore
	import ImageAiInTradingAndRetail from '@/assets/articles/main-photo/ai-in-trading-and-retail.png';
	// @ts-ignore
	import ImageArtificialIntelligenceInSupplyChainManagement from '@/assets/articles/main-photo/artificial-intelligence-in-supply-chain-management.png';
  // @ts-ignore
  import ImageExplainableAI from '@/assets/articles/main-photo/explainable-ai.jpg';
  // @ts-ignore
  import ImageExplainerDashboard from '@/assets/articles/main-photo/explainer-dashboard.png';
	// @ts-ignore
	import ImageUltimateGuideToNaturalLanguageProcessingCourses from '@/assets/articles/main-photo/ultimate-guide-to-natural-language-processing-courses.png';
	// @ts-ignore
	import ImageWorkshopsPhilipines1 from '@/assets/pages/foundation/warsztaty-filipiny-1.jpg';

	@Component({
		components: {
			ArticleCard,
			URL,
		},
	})
    export default class ArticleList extends Vue {
        @Prop() public readonly limit!: boolean;

		get ImageAiInTradingAndRetail() { return ImageAiInTradingAndRetail; }
		get ImageArtificialIntelligenceInSupplyChainManagement() { return ImageArtificialIntelligenceInSupplyChainManagement; }
    get ImageExplainableAI() { return ImageExplainableAI; }
    get ImageExplainerDashboard() { return ImageExplainerDashboard; }
    get ImageUltimateGuideToNaturalLanguageProcessingCourses() { return ImageUltimateGuideToNaturalLanguageProcessingCourses; }
		get ImageWorkshopsPhilipines1() { return ImageWorkshopsPhilipines1; }
    }
