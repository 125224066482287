











    import 'reflect-metadata';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class ArticleCard extends Vue {
        @Prop() public readonly img!: string;

        @Prop() public readonly date!: string;

        @Prop() public readonly title!: string;

        @Prop() public readonly description!: string;
    }
