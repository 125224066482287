<template>
    <div class="articles">
        <div class="main-limiter margin-bottom-6">
            <h1 class="margin-bottom-0">Articles</h1>
            <p class="text-center text-bold margin-top-0a"></p>
        </div>

        <div class="main-limiter margin-top-5 margin-bottom-5">
            <ArticleList/>
        </div>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
	import ArticleList from '@/common/ArticleList';

    import URL from "@/components/URL";

	import ImageAiInTradingAndRetail from '@/assets/articles/main-photo/ai-in-trading-and-retail.png';
	import ImageArtificialIntelligenceInSupplyChainManagement from '@/assets/articles/main-photo/artificial-intelligence-in-supply-chain-management.png';
	import ImageUltimateGuideToNaturalLanguageProcessingCourses from '@/assets/articles/main-photo/ultimate-guide-to-natural-language-processing-courses.png';

	@Component({
    components: {
			ArticleList,
      URL,
    },
        metaInfo() {
            return {
                title: 'Articles',
            }
        }
    })
    export default class Articles extends Vue {
		get ImageAiInTradingAndRetail() { return ImageAiInTradingAndRetail; }
		get ImageArtificialIntelligenceInSupplyChainManagement() { return ImageArtificialIntelligenceInSupplyChainManagement; }
		get ImageUltimateGuideToNaturalLanguageProcessingCourses() { return ImageUltimateGuideToNaturalLanguageProcessingCourses; }
    }
</script>
